//Vercel Api
// export const Base_Url = "https://proposal-vercel-one.mkisan.com/api"
// export const Base_Url = "https://proposal-vercelapi.mkisan.com/api"
// export const Base_Url = "https://determined-easley.184-168-123-97.plesk.page/api"
// export const Base_Url = "https://nifty-herschel.184-168-123-97.plesk.page/api"

//Master Api
export const Base_Url = "https://api.master.proposal.ust.outbooks.com/api"

// //temporyBAseURl;
// export const Base_Url = "https://angry-blackburn.101-53-133-129.plesk.page/api"

//Pre-Prod Api
// export const Base_Url = "https://api.preprod.proposal.ust.outbooks.com/api"

//Production Api
// export const Base_Url = "https://api.app.proposal.ust.outbooks.com/api"


//Local redirect
// export const redirectUri = "http://localhost:3000";
//Client Vercel redirect
// export const redirectUri = "https://proposal-tool.vercel.app";
//New Vercel redirect
// export const redirectUri = "https://proposal-tool-one.vercel.app";
// export const redirectUri = "https://proposal-tool-dev.vercel.app";

//Master redirect
export const redirectUri =  "https://master.proposal.outbooks.com";

//Pre-Prod redirect
// export const redirectUri =  "https://preprod.proposal.outbooks.com";

//Production redirect
// export const redirectUri =  "https://app.proposal.outbooks.com";
// export const mergePdfApiUrl = "http://localhost:3001/mergeUserPdfs";
// export const generatePdfUrl = "http://localhost:3001/generatePdf";
export const mergePdfApiUrl = "https://loving-wilson.101-53-133-129.plesk.page/mergeUserPdfs";
export const generatePdfUrl = "https://loving-wilson.101-53-133-129.plesk.page/generatePdf";
export const saveImage = "https://loving-wilson.101-53-133-129.plesk.page/save-image"
// export const saveImage = "http://localhost:3001/save-image";
// export const mergePdfApiUrl = "https://api.pdfgenration.proposal.ust.outbooks.com/mergeUserPdfs";
// export const generatePdfUrl = "https://api.pdfgenration.proposal.ust.outbooks.com/generatePdf";
// export const saveImage = "https://api.pdfgenration.proposal.ust.outbooks.com/save-image"
export const CompaniesHouseBase_Url = "https://api.companieshouse.gov.uk"


export const OldOutbooksBaseUrl = "https://proposal.outbooks.com/api"
